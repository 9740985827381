<template>
  <div class="admin-feed-block AdminSubscriptionsReport " :class="{ loading: loading }">
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo ReportInfoGeneral">
        <LargeButton
          v-for="(creator, index) in items"
          :key="index"
          :label="`<strong>@${creator.user}</strong>`"
          :desc="`${creator.name}`"
          :UserAvatar="creator"
          :iconSize="`48px`"
          :rightDesc="
            !$isMobile &&
              `<div class='userListTotals'>
             <div class='totalBlock ${$screen.width >= 1280 ? '' : 'hide hidden'}'>
              <div skeleton nowrap strong>${Go.number_format(creator.totalSubscriptions, 0)}</div>
              <small skeleton>${$locale['subscriptions']}</small>
             </div>
             <div class='totalBlock ${$screen.width >= 1280 ? '' : 'hide hidden'}'>
              <div skeleton nowrap strong>${Go.number_format(creator.totalAmountCredits, 0)}</div>
              <small skeleton>${$locale['credits']}</small>
             </div>
             <div class='totalBlock'>
              <div skeleton nowrap strong>$ ${Go.number_format(creator.totalSalesUSD, 2)}</div>
              <small skeleton>${$locale['usd']}</small>
             </div>
             <div class='totalBlock'>
              <div skeleton nowrap strong>$ ${Go.number_format(creator.totalSalesUSDNet, 2)}</div>
              <small skeleton>${$locale['usd']} ${$locale['net']}</small>
             </div>
             <div class='totalBlock commission'>
              <div skeleton nowrap strong number>$ ${Go.number_format(
                Go.getPercentValue(creator.commissionPercent, creator.totalSalesUSDNet),
                2,
              )}</div>
              <small skeleton>${creator.commissionPercent} %</small>
             </div>
            </div>`
          "
          @click="UserStatisticsResume(creator)"
        >
          <div padding-top v-if="$isMobile">
            <div skeleton nowrap>
              $
              <strong>{{ Go.number_format(creator.totalSalesUSD, 2) }}</strong>
              {{ $locale["usd"] }}
            </div>
          </div>
        </LargeButton>
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminStatisticsUsersCreatorsCard: () => import("./AdminStatisticsUsersCreatorsCard.vue"),
  },
  data: function() {
    return {
      loading: false,
      store: Go.store("sales_by_creator"),
      items: [],
      statistics: { totalItems: 0, page: 1 },
    };
  },
  methods: {
    getStatistics: async function({ page = 1 } = {}) {
      this.isLoading(true);
      await Go.sleep(250);

      try {
        this.query = Go.objectToQuery(this.$route.query);
        this.req = await this.api.get(`admin/statistics-creators-general?${this.query}&page=${page}`);
        this.reqSuccess(this.req.data);
      } catch (error) {
        this.reqError(error);
      }

      this.isLoading(false);
    },
    reqSuccess: function({ statistics, creators: items }) {
      if (statistics && Number(statistics.page) === 1) {
        this.store.set("statistics", statistics);
        this.store.set("items", items);
        this.statistics = statistics;
        this.$store.state.admin.salesByCreatorList.statistics = statistics;
        this.items = items;
        return;
      }

      this.statistics = statistics;
      this.items = [...this.items, ...items];
      this.store.set("statistics", statistics);
      this.store.set("items", this.items);
    },
    reqError: function(data = {}) {
      this.showMessage(data);
    },
    UserStatisticsResume: function(creator) {
      this.view({
        user: creator,
        title: `@${creator.user}`,
        component: () => import("./UserStatisticsResume.vue"),
        class: "bottom menu",
        closeOutside: true,
        animation: "bottomIn",
        statistics: this.statistics,
      });
    },
  },
  mounted: function() {
    this.items = this.store.get("items") || [];
    this.statistics = this.store.get("statistics") || { totalItems: 0 };
    this.getStatistics({ page: 1 });
  },
};
</script>
