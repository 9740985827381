export default {
  computed: {
    startDate: function() {
      return this.$route.query.startDate || this.statistics.startDate;
    },
    endDate: function() {
      return this.$route.query.endDate || this.statistics.endDate;
    },
  },
  watch: {
    "$route.query": function() {
      if (Go.is(this.getStatistics, "Function")) {
        this.getStatistics({ page: 1 });
      }
    },
  },
};
